import API_URL from '../../constants';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import './TablaLicencias.css';

function parseDate(date) {
    var a = new Date(date);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var day = a.getDate();
    var date_time = day + ' ' + month + ' ' + year;
    return date_time;
}
class TablaLicencias extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            licenciaSeleccionada: '',
            empresa: '',
            proyecto: '',
            licencias_compradas: '',
            nuevas_licencias: '',
            licencias_restantes: '',
            nuevo_numero_restantes: ''
        }
        this.handleModificarRestantes = this.handleModificarRestantes.bind(this);
        this.handleModificarCompradas = this.handleModificarCompradas.bind(this);
    }

    llamarAPIclaves = () => {

    }
    search = (event) => {
        let input, filter, table, tr, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("tabla-licencias");
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {

            let licencia = tr[i].getElementsByTagName("td")[0];
            let empresa = tr[i].getElementsByTagName("td")[1];
            let proyecto = tr[i].getElementsByTagName("td")[2];
            if (empresa || licencia || proyecto) {
                txtValue = empresa.textContent || empresa.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }
    handleEliminar = async (event) => {

        await this.setState({
            licenciaSeleccionada: event.target.value
        });
        fetch(
            `${API_URL}api/eliminarLicencia`,
            {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'licencia': this.state.licenciaSeleccionada })
            })
            .then(response => {
                return response.json()
            })
            .then(datos => {
                alert(datos.mensaje)
                fetch(`${API_URL}api/listaLicencias`)
                    .then(response => {
                        return response.json()
                    })
                    .then(datos => {
                        this.setState({ rows: datos });
                    })
            })

    }
    handleModificarCompradas = async (event) => {
        await this.setState({
            licenciaSeleccionada: event.target.value,
            empresa: event.target.dataset.empresa,
            licencias_compradas: event.target.dataset.licencias_compradas,
            licencias_restantes: event.target.dataset.licencias_restantes
        });
    }
    handleModificarRestantes = async (event) => {
        await this.setState({
            licenciaSeleccionada: event.target.value,
            empresa: event.target.dataset.empresa,
            licencias_compradas: event.target.dataset.licencias_compradas
        });
    }
    handleModificarNuevoNumeroCompradas = async (event) => {
        await this.setState({
            nuevas_licencias: event.target.value
        });
    }
    handleModificarNuevoNumeroRestantes = async (event) => {
        await this.setState({
            nuevo_numero_restantes: event.target.value
        });
    }
    handleSubmitModificarCompradas = (event) => {
        event.preventDefault();
        // Al hacer click, hacemos un PUT en la tabla 1, modificando el número de licencias compradas
        if ((!parseInt(this.state.nuevas_licencias) || this.state.nuevas_licencias < 0)) {
            window.alert('Licencias tiene que ser un numero positivo')
            return
        }
        let body = {
            "licencias_compradas": this.state.licencias_compradas,
            "licencias_restantes": this.state.licencias_restantes,
            "nuevas_licencias": this.state.nuevas_licencias,
            "licenciaSeleccionada": this.state.licenciaSeleccionada
        }
        fetch(
            `${API_URL}api/modificarLicenciasCompradas`,
            {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                return response.json()
            })
            .then(datos => {
                alert(datos.mensaje)
                fetch(`${API_URL}api/licencias/listaLicencias`)
                    .then(response => {
                        return response.json()
                    })
                    .then(datos => {
                        this.setState({ rows: datos });
                    })
            })

    }
    handleSubmitModificarRestantes = (event) => {
        // Al hacer click, hacemos un PUT en la tabla 1, modificando el número de licencias restantees
        event.preventDefault();
        if ((!parseInt(this.state.nuevo_numero_restantes) || this.state.nuevo_numero_restantes < 0) && this.state.nuevo_numero_restantes !== '0') {
            window.alert('Licencias tiene que ser un numero positivo')
            return
        }

        if (parseInt(this.state.nuevo_numero_restantes) > parseInt(this.state.licencias_compradas)) {
            window.alert('No puede ser un número mayor al numero de licencias adquiridas')
            return
        }
        let body = {
            "licencias_compradas": this.state.licencias_compradas,
            "licencias_restantes": this.state.licencias_restantes,
            "nuevas_licencias": this.state.nuevas_licencias,
            "licenciaSeleccionada": this.state.licenciaSeleccionada,
            "nuevo_numero_restantes": this.state.nuevo_numero_restantes
        }

        fetch(
            `${API_URL}api/modificarLicenciasRestantes`,
            {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                return response.json()
            })
            .then(datos => {
                alert(datos.mensaje)
                fetch(`${API_URL}api/listaLicencias`)
                    .then(response => {
                        return response.json()
                    })
                    .then(datos => {
                        this.setState({ rows: datos });
                    })
            })

    }
    componentDidMount() {
        fetch(`${API_URL}api/listaLicencias`)
            .then(response => {
                return response.json()
            })
            .then(datos => {
                this.setState({ rows: datos });
            })
    }
    render() {

        let filas = this.state.rows;
        const { SearchBar } = Search;
        let products = []
        const columns = [
            {
                dataField: 'licencia',
                text: 'Licencia',
                sort: true
            },
            {
                dataField: 'empresa',
                text: 'Empresa',
                sort: true
            },
            {
                dataField: 'proyecto',
                text: 'Proyecto',
                sort: true
            },
            {
                dataField: 'windows',
                text: 'Windows'
            },
            {
                dataField: 'ios',
                text: 'ios'
            },
            {
                dataField: 'licencias_compradas',
                text: 'Licencias Compradas'
            },
            {
                dataField: 'licencias_restantes',
                text: 'Licencias Restantes'
            },
            {
                dataField: 'fecha_compra',
                text: 'Fecha compra'
            },
            {
                dataField: 'eliminar',
                text: ''
            }
        ];
        for (let fila of filas) {
            let windows, ios;
            if (fila.windows) windows = <img alt="tick" src={require("../media/tick.jpg")} />;
            if (fila.ios) ios = <img alt="tick" src={require("../media/tick.jpg")} />;
            let licencias_compradas = <div>{fila.licencias_compradas}<button className='btn btn-outline-dark btn-modificar' value={fila.licencia} data-empresa={fila.empresa} data-licencias_compradas={fila.licencias_compradas} data-licencias_restantes={fila.licencias_restantes} data-toggle="modal" data-target="#modalCambiarCompradas" onClick={this.handleModificarCompradas}><img alt="editar" src={require("../media/editar.jpg")} /></button></div>;
            let licencias_restantes = <div>{fila.licencias_restantes}<button className="btn btn-outline-dark btn-modificar" value={fila.licencia} data-empresa={fila.empresa} data-licencias_compradas={fila.licencias_compradas} data-toggle="modal" data-target="#modalCambiarRestantes" onClick={this.handleModificarRestantes}><img alt="editar" src={require("../media/editar.jpg")} /></button></div>
            let create_date = parseDate(fila.fecha_compra)
            let eliminar = <td><button className="btn btn-outline-dark" value={fila.licencia} onClick={this.handleEliminar.bind(this)}><img src={require("../media/basura.jpg")} /></button></td>
            let item = {
                licencia: fila.licencia,
                empresa: fila.empresa,
                proyecto: fila.proyecto,
                windows: windows,
                ios: ios,
                licencias_compradas: licencias_compradas,
                licencias_restantes: licencias_restantes,
                fecha_compra: create_date,
                eliminar : eliminar
            }
            products.push(item);
        }


        return (
            <div>
                {/* // Estos son los modales que saltan si usuario hace click en modificar */}
                <div className="modal fade" id="modalCambiarCompradas" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-tunning" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Modificar licencias {this.state.empresa}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmitModificarCompradas.bind(this)}>

                                    <div className="form-group">
                                        <p>Por favor, indica el número de licencias compradas a añadir</p>
                                        <input type='textarea' value={this.state.nuevas_licencias} onChange={this.handleModificarNuevoNumeroCompradas.bind(this)} />

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-primary">Modificar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="modalCambiarRestantes" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-tunning" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Modificar licencias {this.state.empresa}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmitModificarRestantes.bind(this)}>

                                    <div className="form-group">
                                        <p>Por favor, indica el número de licencias que deseas dejar restantes</p>
                                        <input type='textarea' value={this.state.nuevo_numero_restantes} onChange={this.handleModificarNuevoNumeroRestantes.bind(this)} />

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-primary">Modificar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <ToolkitProvider
                        keyField="licencia"
                        striped
                        hover
                        condensed
                        data={products}
                        columns={columns}
                        search
                    >
                        {
                            props => (
                                <div>
                                    <SearchBar {...props.searchProps} />
                                    <hr />
                                    <BootstrapTable
                                        hover
                                        striped
                                        {...props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>

                </div>
            </div>
        );
    }
}

export default TablaLicencias;
