import API_URL from '../constants';
import React, { Component } from 'react';
import './NuevaLicencia.css';

class NuevaLicencia extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loggedIn: this.props.loggedIn,
      listaEmpresas: [],
      empresa: '',
      proyecto: '',
      licencias_compradas: '',
      variasLicenciasIndividuales: '',
      windows: true,
      ios: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmpresaChange = this.handleEmpresaChange.bind(this);
    this.handleProyectoChange = this.handleProyectoChange.bind(this);
    this.handleNumeroChange = this.handleNumeroChange.bind(this);
    this.handleVariasLicenciasIndividuales = this.handleVariasLicenciasIndividuales.bind(this);
  }

  handleEmpresaChange = (event) => {
    this.setState({
      empresa: event.target.value
    })
  }
  handleProyectoChange = (event) => {
    this.setState({
      proyecto: event.target.value
    })
  }

  handleVariasLicenciasIndividuales = (event) => {
    this.setState({
      variasLicenciasIndividuales: event.target.value
    })
  }
  handleNumeroChange = (event) => {
    this.setState({
      licencias_compradas: event.target.value
      });   
    if (event.target.value === '1') {
      document.getElementById('varias-licencias-individuales').style.display = "block";
    } 
    else { 
      this.setState({
        variasLicenciasIndividuales: 0
      })
      document.getElementById('varias-licencias-individuales').style.display = "none"; 
    }
    console.log(this.state.licencias_compradas)
  }

  handleSelectIos = (event) => {
    this.setState({
      ios: event.target.checked
    })
  }
  handleSubmit = async (event) => {

    if (!this.state.empresa) { this.state.empresa = this.state.listaEmpresas[0] }

    let body = {
      "empresa": this.state.empresa,
      "proyecto": this.state.proyecto,
      "licencias_compradas": this.state.licencias_compradas,
      "varias_licencias_individuales": this.state.variasLicenciasIndividuales,
      "windows": this.state.windows,
      "ios": this.state.ios,
    }
    if (!body.licencias_compradas) {
	    window.alert("Elige el numero de licencias");
	    return;
    }

    fetch(
      `${API_URL}api/nuevaLicencia`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        //credentials: 'include',
        body: JSON.stringify(body)
      })
      .then(response => {
        return response.json()
      })
      .then(datos => {
        window.alert(datos.mensaje)
      })
      
  }
  componentDidMount() {

    fetch(`${API_URL}api/listaEmpresas`)
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(datos => {
        this.setState({ listaEmpresas: datos.results });
      })
  }
  render() {

    let empresas_render = this.state.listaEmpresas.map(empresa => {
      return (
        <option key={empresa} value={empresa}>{empresa}</option>
      )
    })

    return (
      
        <div className="form-generarLicencias">
          <div className="form-group">
            <h3>Generar Licencias</h3>
            <label>Empresa</label>
            <select id="form-control-empresas" className="form-control" value={this.state.empresa} onChange={this.handleEmpresaChange}>
              <option value=" " selected>Cargando empresas, espere por favor...</option>
              {empresas_render}
            </select>
          </div>
          
          <div className="form-group">
            <label htmlFor="exampleFormControlSelect1">Proyecto</label>
            <input className="form-control" type='text' value={this.state.proyecto} onChange={this.handleProyectoChange} />
          </div>
          <div className="form-group">
            <label>Usos por Licencia</label>
            <br></br>
            <input className="radio-numero" type="radio" name="numero_licencias" value="1" onChange={this.handleNumeroChange}/>1
            <input className="radio-numero" type="radio" name="numero_licencias" value="5" onChange={this.handleNumeroChange}/>5
            <input className="radio-numero" type="radio" name="numero_licencias" value="10" onChange={this.handleNumeroChange}/>10
            <input className="radio-numero" type="radio" name="numero_licencias" value="20" onChange={this.handleNumeroChange}/>20
            <input className="radio-numero" type="radio" name="numero_licencias" value="50" onChange={this.handleNumeroChange}/>50
    
            <br></br>
          </div>
          <div id="varias-licencias-individuales">
            <label>¿Cuántas licencias de un sólo uso?</label>
            <input className="form-control" type="text" value={this.state.variasLicenciasIndividuales} onChange={this.handleVariasLicenciasIndividuales}/>
          </div>
          <div className="form-group">
            <label>Sistema operativo</label>
            <br></br>
            <input type="checkbox" name="sistema_operativo" value="windows" checked/>Windows                 
    
            <br></br>
            <input type="checkbox" name="sistema_operativo" value="ios" onChange={this.handleSelectIos}/>iOs
            <br></br>
          </div>

          <button onClick={this.handleSubmit}>Generar</button>
          
        </div>
    
    );
  }
}

export default NuevaLicencia;
