import React, { Component } from 'react';
import TablaTrackers from './TablaTrackers';

class Trackers extends Component {

  render() {
    return (
      <>
        <TablaTrackers history={this.props.history} />
      </>
    );
  }
}

export default Trackers;