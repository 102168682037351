import API_URL from '../constants';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: true
    }

    this.handleLogoutGoogle = this.handleLogoutGoogle.bind(this)
  }

  handleLogoutGoogle(event) {
    fetch('/auth/logout', { credentials: 'include' })
      .then((result) => {
        this.setState({loggedIn :false})
      })
  }
  componentDidMount() {
    fetch(`${API_URL}checkUserLoggedIn`)
    .then((response) => {
      return response.json()
    })
    .then((response) => {
        console.log(response)
      this.setState({loggedIn :response.loggedIn})
    })
  }

  render() {

    if (this.state.loggedIn) {

      return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav-item"><img id="logo" alt="irisbond-logo" src={require("./media/logo.png")} /></li>
              <li className="nav-item"><Link className="nav-link" to="/">HIRU</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/nuevaLicencia">Nueva Licencia</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/licencias">Licencias</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/trackers">Trackers</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/" onClick={this.handleLogoutGoogle}>Logout</Link></li>
            </ul>
          </div>
        </nav>
      );
    }
    else {
      return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav-item"><img id="logo" alt="irisbond-logo" src={require("./media/logo.png")} /></li>
              <li className="nav-item"><Link className="nav-link" to="/">HIRU</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/login">Login</Link></li>            
            </ul>
          </div>
        </nav>
      );

    }
  }
}

export default Header;

