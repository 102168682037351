import API_URL from '../../constants';
import React, { Component } from 'react';
import '../licencias/TablaLicencias';

class AñadirTrackers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trackers: [''],
        }
        this.sendTrackers = this.sendTrackers.bind(this)

    }
    sendTrackers = (event) => {
        let trackers = [];
        let inputs = document.getElementById("id-trackers").childNodes
        for (let input of inputs) {
            trackers.push(input.value)
        }
        fetch(
            `${API_URL}trackers/anadirTrackers`,
            {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(trackers)
            })
            .then(response => {
              return response.json()
            })
            .then(datos => {
              window.alert(JSON.stringify(datos))
            })
    }

    render() {
        return (
            <div className="form-generarLicencias">
                <div className="form-group">
                    <h3>Añadir Trackers</h3>
                </div>

                <div id="id-trackers" className="form-group">
                    <input id="input-trackers" className="form-control" type='text' placeholder="id_tracker"/>
                </div>

                {/*<button onClick={this.add}>Add more trackers</button>*/}
                <br></br>
                <button onClick={this.sendTrackers}>Send trackers</button>

            </div>
        )
    }
}

export default AñadirTrackers;