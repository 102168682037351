import { Link } from 'react-router-dom';
import React, { Component } from 'react';


class BotonAnadir extends Component {

    constructor(props) {
        super(props)
        this.state = {}        
    }
    render() {
        return (
            <button style={{ marginLeft: 300}}type="button" className="btn" ><Link to="/añadirTrackers">Añadir nuevos trackers</Link></button>
        )
    }
}

export default BotonAnadir;