import API_URL from '../constants';
import React, { Component } from 'react';
class Login extends Component {

  constructor(props) {
    super(props);
    this.handleLoginGoogle = this.handleLoginGoogle.bind(this);

  }
  handleLoginGoogle = (event) => {
    window.open(`${API_URL}auth/google`)
  }

  render() {
    return (
      <div>
        <button onClick={this.handleLoginGoogle}>
            <img width="20px" alt="Google sign-in"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
          Login with Google</button>
      </div>
      );
  }
}

export default Login;
