import React, { Component } from 'react';
import TablaLicencias from './TablaLicencias';

class Licencias extends Component {

    render() {
        return ( <
            TablaLicencias history = { this.props.history }
            />
        );
    }
}

export default Licencias;