import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NuevaLicencia from './components/NuevaLicencia';
import Header from './components/Header';
import Licencias from './components/licencias/Licencias';
import Login from './components/Login';
import Trackers from './components/trackers/Trackers';
import AñadirTrackers from './components/trackers/AñadirTrackers';
import ProtectedRoute from './components/ProtectedRoute';
import BienvenidoHiru from './components/BienvenidoHiru';

class App extends Component {

  render() {

    return (
      <div>
        <BrowserRouter>
          <Header history={this.props.history} />
          <Switch>
            <Route exact path="/" component={BienvenidoHiru} />
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/nuevaLicencia" component={NuevaLicencia} />
            <ProtectedRoute path="/licencias" component={Licencias} />
            <ProtectedRoute path="/trackers" component={Trackers} />
            <ProtectedRoute path="/añadirTrackers" component={AñadirTrackers} />
          </Switch>
        </BrowserRouter>
      </div >
    );
  }

}

export default App;
