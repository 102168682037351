import API_URL from '../constants';
import React from 'react'
import { Redirect } from 'react-router-dom'

class ProtectedRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: true
        }
    }

    componentDidMount() {
        fetch(`${API_URL}auth/checkUserLoggedIn`)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                console.log(response)
                this.setState({ loggedIn: response.loggedIn })
            })
    }

    render() {
        const Component = this.props.component;
        const isAuthenticated = this.state.loggedIn;
        return isAuthenticated ? 
        ( <Component />) 
        : 
        ( <Redirect to = {{ pathname: '/login' }}/>
        );
    }
}

export default ProtectedRoute;