import API_URL from '../../constants';
import React, { Component } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BotonAnadir from './BotonAnadir';


function parseDate(date) {
    var a = new Date(date);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var day = a.getDate();
    var date_time = day + ' ' + month + ' ' + year;
    return date_time;
}
class TablaTrackers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            trackerSeleccionado: '',
            empresa: '',
            proyecto: '',
        }

    }
    componentDidMount() {
        fetch(`${API_URL}trackers/trackers`)
            .then(response => {
                return response.json()
            })
            .then(datos => {
                this.setState({ rows: datos });
            })
    }
    render() {

        let filas = this.state.rows;
        const { SearchBar } = Search;
        let products = []
        const columns = [
            {
                dataField: 'tracker',
                text: 'Tracker',
                sort: true
            },
            {
                dataField: 'licencia',
                text: 'Licencia',
                sort: true
            },
            {
                dataField: 'empresa',
                text: 'Empresa',
                sort: true
            },
            {
                dataField: 'proyecto',
                text: 'Proyecto',
                sort: true
            },
            {
                dataField: 'windows',
                text: 'Windows'
            },
            {
                dataField: 'ios',
                text: 'ios'
            },
            {
                dataField: 'fecha_activacion',
                text: 'Fecha activación'
            }
        ];
        for (let fila of filas) {
            let windows, ios;
            if (fila.windows) windows = <img alt="tick" src={require("../media/tick.jpg")} />;
            if (fila.ios) ios = <img alt="tick" src={require("../media/tick.jpg")} />;
            let fecha_activacion = parseDate(fila.fecha_activacion)
            let item = {
                tracker: fila.id_tracker,
                licencia: fila.licencia,
                empresa: fila.empresa,
                proyecto: fila.proyecto,
                windows: windows,
                ios: ios,
                fecha_activacion: fecha_activacion
            }
            products.push(item);
        }

        return (
            <div className="container">


                <ToolkitProvider
                    keyField="tracker"
                    striped
                    hover
                    condensed
                    data={products}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <div>
                                <div>
                                    <SearchBar {...props.searchProps} />
                                    <BotonAnadir />
                                </div>
                                <hr />
                                <BootstrapTable
                                    hover
                                    striped
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>

        );
    }
}

export default TablaTrackers;
